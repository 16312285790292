import React from "react";
import { graphql } from "gatsby";
import classNames from "classnames/bind";
import Image from "@v4/gatsby-theme-talend/src/components/Image/Image";

import * as fullWidthStyles from "./fullWidthImage.module.css";
import stylingOptionsFunction from "@v4/utils/stylingOptions";
const cx = classNames.bind(fullWidthStyles);

export const fragment = graphql`
    fragment FullWidthImage on ContentfulFullWidthImage {
        id
        name
        stylingOptions {
            ...stylingOptions
        }
        image {
            public_id
            width
            height
            format
        }
        mobileImage {
            public_id
            width
            height
            format
        }
    }
`;

const FullWidthImage = ({ moduleId, stylingOptions, image, mobileImage }) => {
    const styleOpts = stylingOptionsFunction(stylingOptions, cx);

    const MainImage = image ? (
        <Image className={cx("mainImage")} image={image[0]} alt={""} width={image[0].width} height={image[0].height} />
    ) : null;

    const MobileImage = mobileImage ? (
        <Image
            className={cx("mobileImage")}
            image={mobileImage[0]}
            alt={""}
            style={{ justifyContent: "center" }}
            imgStyle={{ width: "93px", height: "89px" }}
            width={mobileImage[0].width / 2}
            height={mobileImage[0].height / 2}
        />
    ) : null;

    return (
        <section id={moduleId} className={`${cx("fullWidthImageModule")} ${styleOpts.classString ?? ""}`}>
            {styleOpts && styleOpts.borderTopEl}
            <div className={cx("imageWrapper")}>
                {MobileImage}
                {MainImage}
            </div>
            {styleOpts && styleOpts.borderBottomEl}
        </section>
    );
};

export default FullWidthImage;
